import React, { useState } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ImageCarousel from "../../components/img/ImageCarousel"
import LogoStream from "../../components/img/LogoStream"
import Button from "../../components/Button"

const FaqsPage = () => {
  // use state to track the active answer
  const [activeAnswer, setActiveAnswer] = useState(null)

  const images = [
    {
      src: "/banners/landing-banner-giftcards-2.jpg",
      alt: "Steak",
      link: null,
    },
  ]

  const icon = (
    <img
      src="/general/question-icon.png"
      alt=""
      className="inline-block mt-[-5px]"
    />
  )

  return (
    <Layout>
      <Seo
        title={`FAQs  | The Dining Out Gift Card | Find Out More`}
        description={`Find out where you can use your Dining Out Gift Card, how to check your balance and everything else you might want to know about your gift experience...`}
      />

      <ImageCarousel images={images} />

      <LogoStream />

      <div className="content-wrapper">
        <div className="main">
          <h1>Dining Out gift cards FAQ</h1>

          <div className="faq w-full">
            <button onClick={() => setActiveAnswer(1)}>
              {icon} WHERE CAN I BUY DINING OUT GIFT CARDS?
            </button>
            <p className={`${activeAnswer === 1 ? "" : "hidden"} answer`}>
              Dining Out Gift cards are available to{" "}
              <a href="https://diningout.cashstar.com/">purchase online here</a>
              . If you order online, you can choose either a digital gift card
              received by email or you can send a physical gift card, delivered
              by post inside of a greetings card with your own personalised
              message.
              <br />
              <br />
              You can also buy our physical gift cards from some supermarkets
              and high street retailers as well as many of our restaurants, pubs
              and bars. Just pop into your nearest one and speak to a team
              member.
            </p>
          </div>
          <div className="faq w-full">
            <button onClick={() => setActiveAnswer(2)}>
              {icon} WHERE CAN I SPEND DINING OUT GIFT CARDS?
            </button>
            <p className={`${activeAnswer === 2 ? "" : "hidden"} answer`}>
              Dining Out Gift Cards (whether physical or email) are valid at any
              Mitchells &amp; Butlers venue in the UK. You can search all
              available venues <a href="/book-a-table/">here</a>
            </p>
          </div>
          <div className="faq w-full">
            <button onClick={() => setActiveAnswer(3)}>
              {icon} IN WHAT AMOUNTS ARE YOUR GIFT CARDS AVAILABLE?
            </button>
            <p className={`${activeAnswer === 3 ? "" : "hidden"} answer`}>
              You can credit our gift cards with any amount from £5 up to the
              total value of £250 each.
            </p>
          </div>
          <div className="faq w-full">
            <button onClick={() => setActiveAnswer(4)}>
              {icon} FOR HOW LONG ARE THEY VALID?
            </button>
            <p className={`${activeAnswer === 4 ? "" : "hidden"} answer`}>
              Gift Cards are valid for 24 months from the later of the date that
              any value was last loaded onto the Gift Card, or the date that any
              value on the Gift Card was last redeemed.
            </p>
          </div>
          <div className="faq w-full">
            <button onClick={() => setActiveAnswer(5)}>
              {icon} WHAT DOES THE RECIPIENT RECEIVE WHEN I SEND AN EGIFT CARD?
            </button>
            <p className={`${activeAnswer === 5 ? "" : "hidden"} answer`}>
              An eGift Card is an electronic Gift Card sent via email. On the
              delivery date you choose, a message is sent to the recipient
              informing them of your gift, with a link to retrieve it online.
              The link in the email displays a page showing the eGift Card you
              created (the design you selected, with your recipient's name and
              your personalised message), its value and card details. This page
              also gives simple instructions for redeeming the eGift Card.
            </p>
          </div>
          <div className="faq w-full">
            <button onClick={() => setActiveAnswer(6)}>
              {icon} WHEN I ORDER AN EMAIL EGIFT FOR A FRIEND, HOW WILL I KNOW
              IT HAS BEEN SENT?
            </button>
            <p className={`${activeAnswer === 6 ? "" : "hidden"} answer`}>
              You will receive an email message confirming delivery, and a
              message notifying you that the eGift Cards have been viewed. If
              the eGift Cards go un-viewed for 15 days after delivery, you will
              receive a message with options for resolving the issue. You may
              also contact our Gift Card Team on{" "}
              <a href="tel:03316305409">0331 630 5409</a> or at{" "}
              <a href="mailto:mabgiftcards@bhnetwork.com">
                mabgiftcards@bhnetwork.com
              </a>{" "}
              with the order number from the email you received confirming your
              purchase, and we can let you know if the recipient has viewed
              their gift card.
            </p>
          </div>
          <div className="faq w-full">
            <button onClick={() => setActiveAnswer(7)}>
              {icon} WHAT IF THE EGIFT, SENT BY EMAIL OR TEXT MESSAGE, IS NOT
              RECEIVED?
            </button>
            <p className={`${activeAnswer === 7 ? "" : "hidden"} answer`}>
              Get in touch with our Gift Card Team on{" "}
              <a href="tel:03316305409">0331 630 5409</a> or at{" "}
              <a href="mailto:mabgiftcards@bhnetwork.com">
                mabgiftcards@bhnetwork.com
              </a>{" "}
              and they will respond as soon as possible.
            </p>
          </div>
          <div className="faq w-full">
            <button onClick={() => setActiveAnswer(8)}>
              {icon} HOW DO I SPEND AN EGIFT CARD RECEIVED BY EMAIL OR TEXT
              MESSAGE?
            </button>
            <p className={`${activeAnswer === 8 ? "" : "hidden"} answer`}>
              Just show the email (either on your smartphone or as a print-out)
              or text message, which will contain a unique gift code, to your
              server, who will then redeem it for you.
            </p>
          </div>
          <div className="faq w-full">
            <button onClick={() => setActiveAnswer(9)}>
              {icon} DO I HAVE TO SPEND ALL THE MONEY ON MY GIFT CARD IN ONE GO?
            </button>
            <p className={`${activeAnswer === 9 ? "" : "hidden"} answer`}>
              Not at all. If there is any remaining balance on your gift card,
              feel free to come back and spend it before the expiry date.
            </p>
          </div>
          <div className="faq w-full">
            <button onClick={() => setActiveAnswer(10)}>
              {icon} WHERE CAN I FIND OUT WHAT THE REMAINING BALANCE IS ON MY
              GIFT CARD?
            </button>
            <p className={`${activeAnswer === 10 ? "" : "hidden"} answer`}>
              To check the balance on your gift card, please click{" "}
              <a href="https://www.showmybalance.com/">here</a> and follow the
              simple instructions.{" "}
            </p>
          </div>
          <div className="faq w-full">
            <button onClick={() => setActiveAnswer(11)}>
              {icon} CAN I ORDER LARGE QUANTITIES OF GIFT CARDS?
            </button>
            <p className={`${activeAnswer === 11 ? "" : "hidden"} answer`}>
              Certainly. The easiest way is via this website{" "}
              <a href="https://diningout-biz.cashstar.com/">
                https://diningout-biz.cashstar.com/
              </a>
              <br />
              Alternatively, you can contact the corporate gift card team on
              03302369310 or email us at{" "}
              <a href="mailto:mabforbusiness@bhnetwork.com">
                mabforbusiness@bhnetwork.com
              </a>
            </p>
          </div>
          <div className="faq w-full">
            <button onClick={() => setActiveAnswer(12)}>
              {icon} WHAT ARE THE GIFT CARD TERMS AND CONDITIONS?
            </button>
            <p className={`${activeAnswer === 12 ? "" : "hidden"} answer`}>
              1. The Gift Card may not be exchanged for cash.
              <br />
              2. The Gift Card is not a cheque guarantee, credit, debit or
              charge card.
              <br />
              3. No change will be given but the balance can be used for future
              purchases.
              <br />
              4. We will not be held liable for lost or stolen cards - protect
              this as you would cash.
              <br />
              5. To check the balance of your Gift Card, please visit
              www.showmybalance.com and follow the simple instructions.
              <br />
              6. A Gift Card is valid for 24 months from the later of the date
              that any value was last loaded onto the Gift Card, or the date
              that any value on the Gift Card was last redeemed
              <br />
              7. The Gift Card is valid at any Mitchells &amp; Butlers venue in
              the UK. You can search all available venues at
              www.thediningoutgiftcard.co.uk/. The Gift Card cannot be used (i)
              to make any online purchase (ii) at any Moto or other Mitchells
              &amp; Butlers franchise or (iii) at any site not managed by
              Mitchells &amp; Butlers: please check with your server that the
              Gift Card can be used at the particular site before ordering.
              <br />
              8. Gift Cards can be purchased with a minimum value of £5, up to a
              maximum of £250.
              <br />
              9. eGift Cards should be treated as cash. If deleted they will not
              be resent.
              <br />
              10. eGift Cards will be free to receive.
              <br />
              11. Digital Gift Cards are valid either presented on a smart phone
              or printed out.
              <br />
              12. Gift cards can be used multiple times until all of the
              remaining balance is spent. You can check the balance of your Gift
              Card at{" "}
              <a href="https://www.showmybalance.com">
                https://www.showmybalance.com.
              </a>
              <br />
              <br />
              Further terms and conditions are available{" "}
              <a href="/terms-and-conditions/">here</a>
            </p>
          </div>
          <div className="faq w-full">
            <button onClick={() => setActiveAnswer(13)}>
              {icon} I HAVE AN ISSUE WITH MY GIFT CARD, BUT I CAN'T FIND THE
              ANSWER HERE.
            </button>
            <p className={`${activeAnswer === 13 ? "" : "hidden"} answer`}>
              Please contact our Gift Card Team on{" "}
              <a href="tel:03316305409">0331 630 5409</a>, opening hours are 8am
              to 8pm, 7 days a week, or email on{" "}
              <a href="mailto:mabgiftcards@bhnetwork.com">
                mabgiftcards@bhnetwork.com
              </a>
              . Have your order number to hand.
            </p>
          </div>

          <Button href="https://diningout.cashstar.com/" className="mr-2 mb-2">
            Buy a gift card
          </Button>
          <Button href="/book-a-table/">Book a table</Button>
        </div>
      </div>
    </Layout>
  )
}

export default FaqsPage
